<template>
    <div>
        <div class="row no-gutters">
            <div class="col">
                <el-button type="primary" @click="showWarrantedObjectDialog(null)">添加</el-button>
            </div>
        </div>
        <el-table :data="warrantedObjects" class="mt-2">
            <el-table-column prop="idCard" label="身份证号" sortable />
            <el-table-column prop="name" label="姓名" sortable />
            <el-table-column label="操作" fixed="right" width="70">
                <template #default="scope">
                    <el-button type="text" class="text-danger" @click="deleteWarrantedObject(scope.row.idCard)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :visible.sync="warrantedObjectDialogVisible" title="担保对象" :close-on-click-modal="false"
            append-to-body width="400px">
            <el-form>
                <el-form-item label="身份证号" class="form-item-required">
                    <el-input v-model="warrantedObject.idCard" placeholder="身份证号" />
                </el-form-item>
                <el-form-item label="姓名" class="form-item-required">
                    <el-input v-model="warrantedObject.name" placeholder="姓名" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="addWarrantedObject">确定</el-button>
                <el-button @click="warrantedObjectDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        props: {
            warrantorId: { type: String },
        },
        data() {
            return {
                warrantedObjects: [],
                warrantedObject: {},
                warrantedObjectDialogVisible: false,
            };
        },
        methods: {
            async loadWarrantedObjects() {
                let response = await this.$axios.get('/api/Warrantor/GetWarrantedObjects', { params: { warrantorId: this.warrantorId } });
                this.warrantedObjects = response.data;
            },
            showWarrantedObjectDialog(warrantedObject) {
                if (warrantedObject) {
                    this.warrantedObject = JSON.parse(JSON.stringify(warrantedObject));
                }
                else {
                    this.warrantedObject = {};
                }
                this.warrantedObjectDialogVisible = true;
            },
            async addWarrantedObject() {
                await this.$axios.post('/api/Warrantor/AddWarrantedObject', {
                    warrantorId: this.warrantorId,
                    idCard: this.warrantedObject.idCard,
                    name: this.warrantedObject.name,
                });
                this.warrantedObjectDialogVisible = false;
                this.loadWarrantedObjects();
            },
            async deleteWarrantedObject(idCard) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Warrantor/DeleteWarrantedObject', {
                    warrantorId: this.warrantorId,
                    idCard: idCard,
                });
                this.loadWarrantedObjects();
            },
        },
        created() {
            this.loadWarrantedObjects();
        },
    };
</script>