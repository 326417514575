<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadWarrantors(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showWarrantorDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="warrantors.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="身份证号" sortable />
                <el-table-column label="担保对象" width="120">
                    <template #default="scope">
                        <el-button type="text" @click="showWarrantedObjectsDialog(scope.row)">
                            {{scope.row.warrantedObjects.length}}
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="申请日期" sortable :formatter="$moment.sdFormatter" width="160" />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" class="text-danger" @click="deleteWarrantor(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="warrantors.totalRecords"
                :current-page="warrantorsPagination.currentPage" :page-size="warrantorsPagination.pageSize" class="mt-3"
                @current-change="warrantorsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="warrantorDialogVisible" title="种植户" :close-on-click-modal="false" width="480px">
            <el-form>
                <el-form-item label="注册用户" class="form-item-required">
                    <el-input :value="warrantor.registeredUser.name" readonly placeholder="注册用户">
                        <template #append v-if="warrantor.id==undefined">
                            <el-button @click="selectRegisteredUserDialogVisible=true">选择</el-button>
                        </template>
                    </el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveWarrantor">确定</el-button>
                <el-button @click="warrantorDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <el-dialog :visible.sync="warrantedObjectsDialogVisible" title="担保对象" :close-on-click-modal="false"
            width="800px" @closed="loadWarrantors">
            <warranted-objects v-if="warrantedObjectsDialogVisible" :warrantor-id="warrantor.id" />
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="false"
            @select="selectRegisteredUser" />
    </div>
</template>

<script>
    import WarrantedObjects from './components/WarrantedObjects.vue';

    export default {
        data() {
            return {
                key: null,
                warrantors: {},
                warrantorsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                warrantor: { registeredUser: {} },
                warrantorDialogVisible: false,
                warrantedObjectsDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
            };
        },
        components: { WarrantedObjects },
        methods: {
            async loadWarrantors(resetPage = false) {
                if (resetPage) {
                    this.warrantorsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Warrantor/GetWarrantors', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 3,
                        key: this.key,
                        pageIndex: this.warrantorsPagination.currentPage - 1,
                        pageSize: this.warrantorsPagination.pageSize,
                    }
                });
                this.warrantors = response.data;
            },
            warrantorsPaginationCurrentChange(page) {
                this.warrantorsPagination.currentPage = page;
                this.loadWarrantors();
            },
            showWarrantorDialog(warrantor) {
                if (warrantor) {
                    this.warrantor = JSON.parse(JSON.stringify(warrantor));
                }
                else {
                    this.warrantor = {
                        registeredUser: {},
                    };
                }
                this.warrantorDialogVisible = true;
            },
            selectRegisteredUser(user) {
                this.warrantor.registeredUser.id = user.id;
                this.warrantor.registeredUser.name = user.name;
            },
            async saveWarrantor() {
                await this.$axios.post('/api/Warrantor/AddWarrantor', {
                    registeredUserId: this.warrantor.registeredUser.id,
                });
                this.warrantorDialogVisible = false;
                this.loadWarrantors();
            },
            showWarrantedObjectsDialog(warrantor) {
                this.warrantor = JSON.parse(JSON.stringify(warrantor));
                this.warrantedObjectsDialogVisible = true;
            },
            async deleteWarrantor(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Warrantor/DeleteWarrantor', { id: id });
                this.loadWarrantors();
            },
        },
        created() {
            this.loadWarrantors();
        },
    };
</script>